/*-----------------------------------------------------------------------------------
  Version: 1.0
-----------------------------------------------------------------------------------

CSS INDEX
===================
01. Theme default CSS
02. Header
03. Hero
04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
    COMMON css 
===========================*/
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"); */

body {
  /* font-family: "Roboto", sans-serif; */
  font-weight: normal;
  font-family: "metropolis", sans-serif;
  font-style: normal;
  color: #4b4b4b;
  overflow-x: hidden;

}



/* ========================================== */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
  outline: none;
}

img {
  max-width: 100%;
}

a {
  color: #2676e1;
}

a:hover {
  color: #000000;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
  transition: all linear 0.3s;
}

h1,
h2,
h3,
h4,
h5,a,span,p,
h6 {
  font-family: "metropolis", sans-serif;
  /* font-weight: 900; */
  /* color: #3e4347; */
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4b4b4b;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.slick-slide {
  outline: 0;
}

/*===== All Button Style =====*/
.edit-modal-btn {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #3C3C3C;
    border: 1px solid #3C3C3C;
    padding: 0 30px;
    font-size: 15px;
    line-height: 44px;
    border-radius: 6px;
    width: 60%;
    color: #fff;
    z-index: 5;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.main-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #64a6f0;
  border: 1px solid #64a6f0;
  padding: 0 30px;
  font-size: 15px;
  line-height: 44px;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.main-btn:hover {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.main-btn.small {
  line-height: 34px;
}



.modal-backdrop.show {
  opacity: 0.8;
}

.main-modal .modal-content {
  border-radius: 15px;
}

.main-modal .modal-header {
  border: none;
  padding: 10px 15px 0px 0px;
}

.main-modal .modal-body {
  padding: 0px 30px 30px 30px;
  overflow: hidden;
}

.main-modal .modal-body h5 {
  color: #000000;
  font-weight: 500;
  margin-bottom: 8px;
}

.main-modal .modal-body h6 {
  color: #000000;
  font-weight: 400;
  margin-bottom: 20px;
}

/* .main-modal .modal-body hr {
  background: #f5f5f5;
  height: 4px;
  margin: 20px -35px;
  border: 0;
} */

.main-modal .modal-body .linkBox {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}

.main-modal .modal-body .linkBox.last {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

.main-modal .modal-body .linkBox .imgBox {
  width: 50px;
}

.main-modal .modal-body .linkBox .textBox {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.main-modal .modal-body .linkBox .textBox span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #848484;
}

.main-modal .btn-close {
  border: none;
  background: transparent;
}

.main-modal .btn-close::before {
  content: "\f057";
  font-family: "Font Awesome 5 Pro";
  color: #bababa;
  font-size: 24px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.main-modal .btn-close:hover::before {
  color: #000000;
}

.implant-img {
  color: black;
}

.create-post .modal-content {
  border-radius: 15px;
}

.create-post .modal-header {
  border: none;
  padding: 10px 15px 0px 0px;
  position: absolute;
  right: 0;
  z-index: 99;
}

.create-post .modal-body {
  padding: 20px 30px 30px 30px;
  overflow: hidden;
}

.create-post .modal-body h5 {
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.create-post .btn-close {
  border: none;
  background: transparent;
}

.create-post .btn-close::before {
  content: "\f057";
  font-family: "Font Awesome 5 Pro";
  color: #bababa;
  font-size: 24px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.create-post .btn-close:hover::before {
  color: #000000;
}


.create-post .userDetail {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 0px !important;
}

.create-post .userDetail .imgBox {
  width: 60px;
}

.create-post .userDetail .imgBox img {
  border-radius: 50%;
}


.create-post .userDetail .nameBox h6 {
  font-size: 15px;
  font-weight: 500;
}

.create-post .userDetail .nameBox h6 span {
  color: #a8a8a8;
  font-size: 14px;
  font-weight: 400;
}

.create-post .userDetail .nameBox a {
  color: #3e4347;
  font-size: 14px;
}

.create-post .postBox {
  position: relative;
  /* margin-top: 25px; */
}

.create-post .postBox img {
  position: absolute;
  /* top: 15px; */
  right: 15px;
}

.create-post .postBox textarea {
  width: 100%;
  height: 100%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  resize: none;
  padding: 10px;
}

.create-post .postBox textarea::placeholder {
  color: #bfbfbf;
}

.create-post .iconBox {
  display: flex;
  /* align-items: center;
  justify-content: space-between; */
  font-size: 13px;
  color: #3f3d3d;
  flex-wrap: wrap;
  margin-top: 25px;
  gap: 14px;
  align-items: flex-end;
}

.create-post .iconBox .c_new{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  min-width: 50px;
}

.text_post_btn{
  background-color: #64A6F0;
  color: seashell;
  border: none;
  border-radius: 15px;
  transition: ease-in 0.2s;
  padding: 10px 0px;
  margin-top: 20px;
}

.text_post_btn:hover{
background-color: #006DFF;
}

.text_palceholder{
  position: absolute;
  opacity: 1;
  color: #949090;
  font-size: 15px;
  bottom: 5%;
  left: 36%;
}

.iconBox_new{
  justify-content: center;
}

.create-post .iconBox div {
  color: #848484;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
}

.create-post .iconBox div img {
  margin-bottom: 5px;
  width: 20px;
}

.create-post .bg-head {
  font-size: 15px;
  font-weight: 500;
  color: #787c81;
  margin-top: 20px;
  margin-bottom: 15px;
}

.create-post .iconBox.bg {
  margin-top: 0px;
}

.create-post .iconBox.bg a {
  flex-basis: auto;
}

.create-post .iconBox.bg a img {
  margin: 0px;
  border-radius: 17px;
}

.create-post-btn { 
  height: 40px;
  width:425px;
  text-align: center;
  background-color: #64a6f0;
  color: white;
  border: none;
  border-radius: 40px;
}

.create-post hr {
  background: #ebebeb;
  height: 2px;
  margin: 25px 0px 20px;
  border: 0;
}

.create-post .main-btn {
  margin-top: 30px;
  border-radius: 50px;
  font-weight: 500;
  width: 100%;
  text-transform: uppercase;
}

.filter-popup .modal-content {
  border-radius: 15px;
}

.filter-popup .modal-header {
  border: none;
  padding: 10px 15px 0px 0px;
  position: absolute;
  right: 0;
  z-index: 99;
}

.filter-popup .modal-body {
  padding: 20px 30px 30px 30px;
  overflow: hidden;
}

.filter-popup .modal-body h5 {
  color: #000000;
  font-weight: 500;
  margin-bottom: 25px;
  text-align: center;
}

.filter-popup .btn-close {
  border: none;
  background: transparent;
}

.filter-popup .btn-close::before {
  content: "\f057";
  font-family: "Font Awesome 5 Pro";
  color: #bababa;
  font-size: 24px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.filter-popup .btn-close:hover::before {
  color: #000000;
}

.filter-popup .modal-body .linkBox {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.filter-popup .modal-body .linkBox:last-child {
  margin-bottom: 0px;
}

.filter-popup .modal-body .linkBox .imgBox {
  width: 50px;
}

.filter-popup .modal-body .linkBox .textBox {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.emoji-popup .modal-content {
  border-radius: 15px;
}

.emoji-popup .modal-header {
  border: none;
  padding: 0px 5px 0px 0px;
}

.emoji-popup .modal-body {
  padding: 0px 20px 25px 25px;
  overflow: hidden;
}

.emoji-popup .btn-close {
  border: none;
  background: transparent;
}

.emoji-popup .btn-close::before {
  content: "\f057";
  font-family: "Font Awesome 5 Pro";
  color: #bababa;
  font-size: 24px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.emoji-popup .btn-close:hover::before {
  color: #000000;
}

.emoji-popup .modal-body .emojiBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.emoji-popup .modal-body .emojiBox img {
  cursor: pointer;
  width: 50px;
}

.create-call .modal-content {
  border-radius: 15px;
}

.create-call .modal-header {
  border: none;
  padding: 10px 15px 0px 0px;
  position: absolute;
  right: 0;
  z-index: 99;
}
.create-call .modal-header {
  border: none;
  padding: 10px 15px 0px 0px;
  position: absolute;
  right: 0;
  z-index: 99;
}

.create-call .modal-body {
  padding: 20px 30px 30px 30px;
  overflow: hidden;
}

.create-call .modal-body h5 {
  color: #000000;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

.create-call .btn-close {
  border: none;
  background: transparent;
}

.create-call .btn-close::before {
  content: "\f057";
  font-family: "Font Awesome 5 Pro";
  color: #bababa;
  font-size: 24px;
  font-weight: 300;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.create-call .btn-close:hover::before {
  color: #000000;

}

/* .create-call .modal-body p {
  color: #848484;
  font-size: 12px;
  line-height: 18px;
} */

.create-call .modal-body .callType {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
}

.create-call .modal-body .callType h6 {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.create-call .modal-body .callType a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 36px;
  border-radius: 6px;
  background: #f2f2f2;
}

.create-call .modal-body .callType a.active {
  background: #2676e1;
}

.create-call .modal-body .callType a.active img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.create-call .modal-body .linkBox {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.create-call .modal-body .linkBox:last-child {
  margin-bottom: 0px;
}

.create-call .modal-body .linkBox .imgBox {
  width: 50px;
}

.create-call .modal-body .linkBox .textBox {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.create-call .modal-body .linkBox.copy-link {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 6px 12px;
  margin-left: -12px;
  margin-right: -12px;
}

.create-call .modal-body .linkBox.copy-link .textBox {
  font-size: 12px;
  font-weight: 500;
  color: #2676e1;
}

.create-call .modal-body .linkBox.copy-link .imgBox {
  width: 30px;
}

.create-call-body-area {
  /* border: 1px solid black; */
  height: 75vh;
  overflow-y: scroll;
}

.create-call-body-area::-webkit-scrollbar {
  width: 0px;
}

.deleteDeactivation .form-check {
  float: left;
}

.create-reels-1{
  max-height: 83vh !important;
  overflow: hidden;
  overflow-y: scroll;
}
.create-reels-1::-webkit-scrollbar{
width: 0px !important;
}


.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 999;
  display: none;
}

.back-to-top a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #4580c2;
  border-radius: 6px;
  color: #fff;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-position: center center;
  background-repeat: no-repeat;
}

.preloader-close {
  position: fixed;
  z-index: 999999;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  right: 40px;
  bottom: 40px;
  font-weight: 600;
  line-height: 60px;
  background: #4580c2;
  padding: 0 15px;
  border-radius: 5px;
}

.loader-wrap {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.loader-wrap .layer-one {
  position: absolute;
  left: 0%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-two {
  position: absolute;
  left: 33.3333%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-three {
  position: absolute;
  left: 66.6666%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f4f9ff;
}

/*===========================
    HEADER css 
===========================*/

.header-area {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1010;
  background: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
  /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05); */
}

.header-toggle-name {
  color: black;
}

/* Navneet */

.read-more {
  font-weight: 500;
  color: #000000;
  margin-left: 5px;
  cursor: pointer;
}

.login-area {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.login-area .imgBox {
  position: relative;
  padding-top: 50px;
}

.login-area .imgBox .imgOne {
  transform: translate(15%, 0%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.login-area .imgBox .imgTwo {
  transform: translate(-15%, 5%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.login-area .imgBox:hover .imgOne {
  transform: translate(8%, 8%);
}

.login-area .imgBox:hover .imgTwo {
  transform: translate(-4%, 0%);
}

.login-area .loginBox {
  position: relative;
  padding-top: 50px;
}


.login-area .loginBox .logo-box {
  text-align: center;
  margin-bottom: 40px;
}

.login-area .loginBox .socialLogin {
  display: flex;
  flex-direction: column;
}

.login-area .loginBox .socialLogin .socialIcon {
  background: #f5f5f5;
  color: #525252;
  padding: 15px 0px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 50px;
}

.login-area .loginBox .socialLogin .socialIcon:last-child {
  margin-bottom: 0px;
}

.login-area .loginBox .socialLogin .socialIcon img {
  margin-right: 30px;
}

.login-area .loginBox .divider {
  position: relative;
  border-bottom: 1px solid #bfbfbf;
  margin: 40px 0px;
}

.login-area .loginBox .divider b {
  position: absolute;
  top: -10px;
  left: 50%;
  background: #ffffff;
  transform: translateX(-50%);
  color: #525252;
  font-weight: 400;
  padding: 0px 25px;
}

.login-area .loginBox .btn-box {
  margin-bottom: 30px;
}

.login-area .loginBox .btn-box .main-btn {
  display: block;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 50px;
  border-radius: 50px;
}

.login-area .loginBox .bottomBox p {
  font-size: 14px;
}

.login-area .loginBox .bottomBox a {
  color: #525252;
  font-weight: 600;
  text-decoration: underline;
}

.login-area .loginBox .bottomBox a:hover {
  text-decoration: none;
}

.login-area .loginBox form {
  padding: 50px 0px;
}

.login-area .loginBox input {
  width: 100%;
  height: 60px;
  font-size: 16px;
  line-height: 60px;
  border: 1px solid #ebebeb;
  border-radius: 50px;
  outline: none;
  padding: 0 30px;
  margin-bottom: 30px;
}

.login-area.signup {
  position: relative;
}

.login-area.signup .imgBox {
  padding: 0;
}

.login-area.signup .loginBox {
  padding: 50px 70px 50px 70px;
}

.login-area.signup .loginBox .btn-box {
  margin-top: 30px;
}

.userNamePage{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
  margin-top: 50px;
}

.userNamePage>section:first-child{
  background-color: black;
  height: 100%;
  width: 55%;
}

.userNamePage>section:nth-child(2)>form{
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  align-items: center;
  width: 50%;
  margin: auto;
}

.userNamePage>section:nth-child(2)>form>img{
  margin-bottom: 50px;
}

.userNamePage section:nth-child(2) form input::placeholder{
  color: #d3d3d3;
}

.header-nav-box .logo-box {
  text-align: center;
}

.header-nav-box .logo-box img {
  width: 125px;
}

.header-nav-box .input-box {
  position: relative;
}

.header-nav-box .input-box input {
  width: 100%;
  height: 45px;
  border-radius: 40px;
  border: 0;
  background: #f5f5f55b;
  padding-left: 60px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.header-nav-box .input-box span {
  width: 100%;
  height: 45px;
  border-radius: 40px;
  border: 0;
  padding-left: 60px;
}

.header-nav-box .input-box input::placeholder {
  color: #bababa;
}

.header-nav-box .input-box button {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background: transparent;
  border: 0;
  color: #bababa;
}

.header-nav-box .btn-box {
  position: relative;
}

.header-nav-box .btn-box .new-post {
  display: inline-block;
  text-align: center;
  border: 0;
  border-radius: 50px;
  background-color: #64a6f0;
  color: #ffffff;
  /* padding: 10px 25px; */
  font-size: 12px;
  line-height: 22px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  width: 158px;
}

.header-nav-box .btn-box .new-post i {
  font-size: 20px;
  margin-right: 8px;
  position: relative;
  top: 3px;
}

.header-nav-box .btn-box .new-post:hover {
  background-color: #000000;
  color: #ffffff;
}

.header-nav-box .notify-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header-nav-box .notify-box .bell {
  position: relative;
}

.header-nav-box .notify-box .bell span {
  position: absolute;
  top: 2px;
  right: 5px;
  background: #ee1010cc;
  border: 2px solid #ee1010;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.notify-box img {
  max-width: 84%;
}

.header-nav-box .notify-box a.active img {
  filter: invert(60%) sepia(55%) saturate(910%) hue-rotate(185deg)
    brightness(97%) contrast(93%);
  -webkit-filter: invert(60%) sepia(55%) saturate(910%) hue-rotate(185deg)
    brightness(97%) contrast(93%);
}



.section-main {
  position: relative;
}

.section-main .left-block {
  position: relative;
  padding: 100px 40px 0px 40px;
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.1);
  height: 100vh;
  overflow: auto;
  /* margin-top: 50px; */
}



.section-main .left-block::-webkit-scrollbar {
  display: none;
}

.section-main .left-block .profileBox {
  text-align: center;
}

.section-main .left-block .profileBox .imgBox {
  position: relative;
  display: inline;
}

.section-main .left-block .profileBox .imgBox img {
  width: 120px;
  border-radius: 50%;
  margin: 25px 0px 0px;
}

.section-main .left-block .profileBox .imgBox i {
  position: absolute;
  top: 50px;
  right: 0px;
  background: #ebebeb;
  color: #000000;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
}

.section-main .left-block .profileBox .imgBox a:hover i {
  background: #ed1e24;
  color: #ffffff;
}

.section-main .left-block .profileBox h3 {
  font-size: 24px;
  font-weight: 600;
}

.lb-head {
  margin: 25px 0px 0px;
}

.section-main .left-block .followBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0px;
}

.section-main .left-block .followBox .innerBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 90px;
  height: 90px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}

.section-main .left-block .followBox .innerBox .userIcon {
  position: absolute;
  top: -8px;
  left: 10px;
  display: flex;
}

/* .section-main .left-block .followBox .innerBox .userIcon img {
  width: 23px;
  border: 1px solid #ffffff;
  border-radius: 50px;
} */

/* .section-main .left-block .followBox .innerBox .userIcon img + img {
  margin-left: -8px;
} */



.section-main .left-block .followBox .innerBox .userIcon b {
  width: 26px;
  height: 23px;
  background: linear-gradient(145deg, #f369ff, #6ac6fc);
  color: #ffffff;
  border-radius: 50%;
  margin-left: -5px;
  text-align: center;
}

.section-main .left-block .followBox .innerBox b {
  display: block;
  font-size: 14px;
}

.section-main .left-block .followBox .innerBox span {
  font-size: 10px;
  color: #a5a5a5;
}

.section-main .left-block .main-menu {
  position: relative;
}

.section-main .left-block .main-menu ul li {
  margin-bottom: 40px;
}

.section-main .left-block .main-menu ul li a {
  display: flex;
  align-items: center;
  color: #3e4347;
  vertical-align: middle;
}

.section-main .left-block .main-menu ul li a.active {
  color: #64a6f0;
  font-weight: 500;
}

.section-main .left-block .main-menu ul li a img {
  margin-right: 30px;
}

.section-main .left-block .main-menu ul li a span {
  margin-right: 10px;
}

.section-main .left-block .main-menu ul li a.active img {
  filter: invert(60%) sepia(55%) saturate(910%) hue-rotate(185deg)
    brightness(97%) contrast(93%);
  -webkit-filter: invert(60%) sepia(55%) saturate(910%) hue-rotate(185deg)
    brightness(97%) contrast(93%);
}

.section-main .center-block {
  position: relative;
  padding: 75px 40px 0px 40px;
  height: 100vh;
  /* overflow: auto; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* margin-top: 50px; */
}

.section-main .center-block::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}
.section-main .center-block::-webkit-scrollbar-thumb {
  background-color: #91bbeb;
  border-radius: 10px;
}

.section-main .center-block .headBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.section-main .center-block .headBlock h5 {
  font-size: 16px;
  font-weight: 700;
}

.section-main .center-block .headBlock span {
  font-size: 14px;
  font-weight: 500;
  color: #02a7fa;
}

.cbs-1-toggle-fav-head-seeAll {
  color: #006DFF;
  cursor: pointer;
}

.cbs-1-toggle-cf-head-seeAll {
  color: #006DFF;
  cursor: pointer;
}

.cbs-2-seeAll{
  font-size: 12px;
  font-weight: 600;
  color: #02a7fa;
  border: none;
  background: none;
  margin-right: 10px;
}

.section-main .center-block .headBlock button {
  font-size: 14px;
  font-weight: 500;
  color: #02a7fa;
  border: none;
  background:none;
}

.section-main .center-block .friendBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-x: scroll;
  margin-bottom: 15px;
}

.section-main .center-block .friendBox::-webkit-scrollbar {
  display: none;
}

.section-main .center-block .friendBox .innerBox {
  text-align: center;
  padding-top: 2px;
  margin-right: 29px;
  flex-shrink: 0;
  cursor: pointer;
}

.section-main .center-block .friendBox .innerBox:first-child {
  padding-left: 2px;
}

.section-main .center-block .friendBox .innerBox:last-child {
  margin-right: 0px;
  padding-right: 2px;
}

.section-main .center-block .friendBox .innerBox .imgBox {
  position: relative;
  display: inline;
}

.section-main .center-block .friendBox .innerBox .imgBox img {
  width: 70px;
  outline: 2px solid #ed1e24;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-bottom: 25px;
}

.section-main .center-block .friendBox .innerBox .imgBox .userIcon {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.section-main .center-block .friendBox .innerBox .imgBox .userIcon img {
  width: 30px;
  outline: none;
  border: 2px solid #4580c2;
  border-radius: 50%;
  margin-bottom: 0px;
  object-fit: fill;
}

.section-main .center-block .friendBox .innerBox span {
  font-size: 12px;
  color: #a5a5a5;
  display: block;
}

.section-main .center-block .storyBox {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-bottom: 40px;
}

.section-main .center-block .storyBox::-webkit-scrollbar {
  display: none;
}

.section-main .center-block .storyBox .innerBox {
  margin-right: 20px;
  flex-shrink: 0;
}

.section-main .center-block .storyBox .innerBox:last-child {
  margin-right: 0px;
}

.section-main .center-block .storyBox .innerBox .imgBox {
  position: relative;
  border-radius: 10px;

  overflow: hidden;
}

.section-main .center-block .storyBox .innerBox .imgBox::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  width: 100%;
  height: 40%;
}

.section-main .center-block .storyBox .innerBox .imgBox img {
  width: 100%;
  border-radius: 10px;

}

.section-main .center-block .storyBox .innerBox .imgBox .userIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
}

.section-main .center-block .storyBox .innerBox .imgBox .userIcon i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #ffffff;
  background: #484748;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.section-main .center-block .storyBox .innerBox .imgBox .userIcon img {
  width: 40px;
  outline: none;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.section-main .center-block .storyBox .innerBox span {
  font-size: 12px;
  color: #ffffff;
}

.section-main .center-block .feedBox {
  position: relative;
  margin: 0 33px 0 33px;
  background: #fff;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}
.section-main .center-block .feedBox .contentArea .imgBox img {
  width: auto;
  height: auto;
  object-fit: contain;
}

.section-main .center-block .feedBox .userArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 20px; */
}



.section-main .center-block .feedBox .userArea .userDetail {
  position: relative;
  display: flex;
  align-items: center;
}

.section-main .center-block .feedBox .userArea .userDetail .imgBox {
  position: relative;
  width: 60px;
  margin-right: 20px;
}



.section-main
  .center-block
  .feedBox
  .userArea
  .userDetail
  .imgBox.active::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: #00b5aa;
  border: 2px solid #ffffff;
  border-radius: 15px;
}

.section-main .center-block .feedBox .userArea .userDetail .imgBox img {
  border-radius: 50%;
}

.section-main .center-block .feedBox .userArea .userDetail .nameBox h6 {
  font-size: 18px;
  font-weight: 600;
  color: #484d54;
  margin: 0;
}

.cb-post-head-name {
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  margin-right: 5px;
  color: black;
}

.cb-post-spinner {
  width: 100%;
  text-align: center;
}

.textPost .html_class{
  overflow: hidden;
}


.loadMoreData {
  /* border: 1px solid black; */
  color: white;
  background-color: #64A6F0;
  padding: 5px 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 100px;
}

.address{
  display: inline;
}

.commentBtnMobile {
  display: none;
}

.section-main .center-block .feedBox .userArea .userDetail .nameBox p {
  font-size: 13px;
  color: #727272;
  margin: 0;
}

.section-main .center-block .feedBox .userArea .userDetail .nameBox p span {
  position: relative;
  /* margin-left: 20px; */
  /* padding-left: 15px; */
}



/* .section-main
  .center-block
  .feedBox
  .userArea
  .userDetail
  .nameBox
  p
  span::before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  background: #727272;
  width: 5px;
  height: 5px;
  border-radius: 5px;
} */

.section-main .center-block .feedBox .userArea .userFollow {
  position: relative;
  display: flex;
  align-items: center;
}

.section-main .center-block .feedBox .userArea .userFollow a {
  color: #727272;
  margin-left: 20px;
  font-size: 20px;
}

.section-main .center-block .feedBox .contentArea {
  margin: 15px 0px;
  position: relative;
  text-align: center;
}

.section-main .center-block .feedBox .contentArea .imgBox {
  position: relative;
}

.section-main .center-block .feedBox .contentArea .imgBox img {
  border-radius: 15px;
   /* max-height: 684px; */
 max-width: 100%;
 margin: auto;
}


.section-main .center-block .feedBox .contentArea .imgBox .slick-dots {
  bottom: 20px;
}



.section-main .center-block .feedBox .contentArea .imgBox .slick-dots li {
  display: inline-block;
  margin: 0 3px;
  width: auto;
  height: auto;
}

.section-main
  .center-block
  .feedBox
  .contentArea
  .imgBox
  .slick-dots
  li
  button {
  background: #eeeeee;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  padding: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.section-main
  .center-block
  .feedBox
  .contentArea
  .imgBox
  .slick-dots
  li
  button:before {
  display: none;
}

.section-main
  .center-block
  .feedBox
  .contentArea
  .imgBox
  .slick-dots
  li.slick-active
  button {
  background: #3e43475d;
  width: 20px;
}

.section-main .center-block .feedBox .contentArea .imgBox .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #ffffff;
  font-size: 42px;
  text-shadow: 0px 0px 10px #000000;
  transform: translate(-50%, -50%);
}

.section-main .center-block .feedBox .contentArea .imgBox .volume-btn {
  position: absolute;
  right: 25px;
  bottom: 25px;
  color: #ffffff;
  font-size: 18px;
  background: #474747;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-main .center-block .feedBox .contentArea .textBox {
  background: #ffffff;
  padding: 20px;
  border: 2px solid #e9e9e9;
  border-radius: 15px;
}

.section-main .center-block .feedBox .contentArea .textBox .textArea,
.section-main .center-block .feedBox .contentArea .textBox .textArea p {
  margin: 0;
}

.section-main .center-block .feedBox .likeArea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 10px; */
}

.section-main .center-block .feedBox .likeArea .leftBox span {
  font-size: 14px;
  font-weight: 500;
  color: #727272;
  margin-right: 40px;
}

.favouritePostBtn,.downloadBtn{
  width: 21px;
}

.zero_comments{
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zero_comments img{
  width: 30%;
}

.zero_comments p{
color: #f3f3f3;
opacity: 0.6;
margin-top: 5px;
font-size: 15px !important;
letter-spacing: 1px;
}

.notify_loader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.zero_notifications{
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zero_notifications img{
  width: 20%;
}

.zero_notifications p {
  color: #d3d3d3;
  opacity: 1;
  margin-top: 5px;
  font-size: 15px !important;
  letter-spacing: 1px;
}


.section-main .center-block .feedBox .likeArea .leftBox span:last-child {
  margin-right: 0px;
}

.section-main .center-block .feedBox .likeArea .leftBox span img {
  margin-right: 5px;
  width: 20px;
}

.section-main .center-block .feedBox .likeArea .rightBox span {
  margin-right: 30px;
  cursor: pointer;
}

.section-main .center-block .feedBox .likeArea .rightBox span:last-child {
  margin-right: 0px;
}

.section-main .center-block .feedBox .textArea {
  position: relative;
  margin-bottom: 25px;
}

.textPost{
  border: 1px solid #03A5F9;
  border-radius: 15px;
  padding: 10px 15px;
  background-color: #f3f3f3;
  margin: 20px 10px;
}

.checkinPost{
  border-radius: 15px;
  background-color: #fdfbfb;
  margin: 20px 10px;
}


.section-main .center-block .feedBox .textArea p {
  font-size: 15px;
  margin-bottom: 10px;
}

.section-main .center-block .feedBox .textArea a {
  font-size: 14px;
  color: #a5a5a5;
  margin-right: 10px;
}

.section-main .center-block .feedBox .textArea a:last-child {
  margin-right: 0px;
}

.section-main .center-block .feedBox .textArea a:hover {
  color: #ed1e24;
}

.section-main .center-block .explore-block {
  position: relative;
}

.section-main .center-block .explore-block .imgBox {
  position: relative;
  margin: 8px;
 display: inline-block;
}

.section-main .center-block .explore-block .imgBox:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.section-main .center-block .explore-block .imgBox:hover:before {
  opacity: 1;
}

/* .section-main .center-block .explore-block .imgBox img {
  border-radius: 15px;
} */

.section-main .center-block .explore-block .imgBox .iconBox {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgb(116 115 115 / 60%);
  border-radius: 50px;
  height: 30px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-main .center-block .explore-block .imgBox .iconBox img {
  border-radius: 0;
}

.cnp-cb-img {
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-radius: 15px;
}

.section-main .center-block .explore-block .imgBox .likeBox {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.section-main .center-block .explore-block .imgBox .viewsBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.section-main .center-block .explore-block .imgBox:hover .viewsBox {
  opacity: 1;
}
.section-main .center-block .explore-block .imgBox:hover .likeBox {
  opacity: 1;
}

.section-main .center-block .explore-block .imgBox .likeBox span {
  color: #ffffff;
  font-weight: 500;
}
.section-main .center-block .explore-block .imgBox .viewsBox span {
  color: #ffffff;
  font-weight: 500;
}

.section-main .center-block .explore-block .imgBox .likeBox img {
  border-radius: 0;
  margin-right: 5px;
}

.section-main .center-block .explore-block .imgBox .textArea {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.section-main .center-block .explore-block .imgBox:hover .textArea {
  opacity: 1;
}

.ReelsProfile{
  /* display: flex; */
  justify-content: center;
}

.section-main .center-block .explore-block .imgBox .textArea p {
  color: #ffffff;
}

.section-main .center-block .explore-block .imgBox .textArea .read-more {
  color: #ffffff;
}

.section-main .center-block .reels-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section-main .center-block .reels-block .imgBox {
  position: relative;
  margin-bottom: 30px;
  width: 36%;
}

.section-main .center-block .reels-block .imgBox:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 45%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.section-main .center-block .reels-block .imgBox img {
  border-radius: 15px;
}

.section-main .center-block .reels-block .imgBox .volume-btn {
  position: absolute;
  right: 25px;
  top: 25px;
  color: #000000;
  font-size: 18px;
  background: #ffffff;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-main .center-block .reels-block .imgBox .contentBox {
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.section-main .center-block .reels-block .imgBox .contentBox .userArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  margin-bottom: 20px;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userFollow
  .main-btn {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50px;
  line-height: 35px;
  font-size: 14px;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userFollow
  .main-btn:hover {
  background: #ffffff;
  color: #000000;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userDetail {
  position: relative;
  display: flex;
  align-items: center;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userDetail
  .name-box {
  text-align: right;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userDetail
  .name-box
  h6 {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userDetail
  .name-box
  p {
  font-size: 13px;
  color: #ffffff;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userDetail
  .img-box {
  position: relative;
  width: 60px;
  margin-left: 20px;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .userArea
  .userDetail
  .img-box
  img {
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.section-main .center-block .reels-block .imgBox .contentBox .textArea {
  padding: 0px 30px;
  margin-bottom: 20px;
}

.section-main .center-block .reels-block .imgBox .contentBox .textArea p {
  color: #ffffff;
  font-size: 15px;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .textArea
  .read-more {
  color: #ffffff;
  font-weight: 400;
}

.section-main .center-block .reels-block .imgBox .contentBox .artistArea {
  display: flex;
  align-items: center;
  padding: 0px 30px;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .artistArea
  .img-box {
  margin-right: 20px;
}

.section-main
  .center-block
  .reels-block
  .imgBox
  .contentBox
  .artistArea
  .name-box
  p {
  color: #ffffff;
}

.section-main .center-block .reels-block .imgBox .likeBox {
  position: absolute;
  bottom: 0;
  left: -70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.section-main .center-block .reels-block .imgBox .likeBox span {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
}

.section-main .center-block .reels-block .imgBox .likeBox span img {
  border-radius: 0;
}

.section-main .center-block .reels-block .imgBox .likeBox span i {
  font-size: 20px;
}


/* *** Center Block > Story 1 ***  */

.cb-stories-1 {
  /* border: 1px solid red; */
  display: flex;
}

.cb-story-1 {
  /* border: 1px solid black; */
  margin: 0px 20px 0px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cb-story-1-img {
  border-radius: 50%;
  border: 1px solid #caced2;

  outline: 2px solid #FF2F2F;
  outline-offset: 2px;

  /* border-top: 3px solid #FF2F2F; */
  /* border-right: 3px solid #5A93FA; */
  /* border-bottom: 3px solid #5CD99B; */
  /* border-left: 3px solid #F369FA; */
  /* padding: 3px; */
}

.cb-story-1-profile {
  outline: 2px solid #0B71F6;
  outline-offset: 1px;
  border-radius: 50%;
  position: relative;
  bottom: 15px;
  border: 1px solid #caced2;
}

.cb-story-1-name {
  /* border: 1px solid black; */
  font-size: 13px;
  color: #787C81;
  height: 20px;
  width: 75px;
  text-align: center;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
}



/* *** Center Block > Story 1 > Popup ***  */

.centerBlockStory1Popup {
  /* border: 1px solid black; */
  height: 700px;
  width: 435px;
}

.centerBlockStory1Popup-area-1 {
  /* border: 1px solid red; */
  position: relative;
}

.centerBlockStory1Popup-area-volume-1 {
  position: absolute;
  top: 27px;
  right: 80px;
  z-index: 1000;
  cursor: pointer;
}

.centerBlockStory1Popup-area-volume-2 {
  position: absolute;
  top: 27px;
  right: 37px;
  z-index: 1000;
  cursor: pointer;
}

.centerBlockStory1Popup-area-threeDot {
  position: absolute;
  top: 25px;
  right: 13px;
  z-index: 1000;
  cursor: pointer;
  font-size: 20px;
}


/* .centerBlockStory1Popup-area-2 {
  background-color: rgb(17, 17, 17);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 0px;
}

.centerBlockStory1Popup-area-comment input {
  height: 40px;
  width: 300px;
  background-color: transparent;
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 20px;
  font-size: 17px;
  color: white;
  font-weight: 300;
}

.centerBlockStory1Popup-area-like {
  cursor: pointer;
}

.centerBlockStory1Popup-area-share {
  cursor: pointer;
} */


.centerBlockStory1Popup-area-2 {
  /* border: 1px solid red; */
  position: relative;
}

.centerBlockStory1Popup-area-comment input {
  height: 40px;
  width: 300px;
  background-color: transparent;
  padding: 5px 15px;
  border: 1px solid gray;
  border-radius: 20px;
  font-size: 17px;
  color: white;
  font-weight: 300;
  position: absolute;
  bottom: 35px;
  left: 17px;
  z-index: 1000;
}

.centerBlockStory1Popup-area-like {
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  left: 340px;
  z-index: 1000;
}

.centerBlockStory1Popup-area-share {
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  left: 385px;
  z-index: 1000;
}











/* *** Center Block > Story 2 ***  */

.cb-story-2 {
  /* border: 1px solid black; */
  display: flex;
  height: 190px;
}

/* *** Center Block > Story 2 > logged in user story ***  */

.cb-story-2-lius {
  /* border: 1px solid red; */
  margin: 5px 15px 0px 0px;
  position: relative;
}

.cb-story-2-lius-stories {
  height: 100%;
  width: 125px;
}

.cb-story-2-lius-add {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid white;
  padding: 7px;
  cursor: pointer;
  z-index: 1;
}

.cb-story-2-lius-input {
  display: none;
  visibility: none;
}

.cb-story-2-lius-story {
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0px 0px 7px 0px gray;
}

.cb-story-2-lius-name {
  z-index: 1;
  position: absolute;
  top: 145px;
  left: 17px;
  font-weight: 500;
  color: white;
}

.createStory2Popup-head {
  text-align: center;
}

.createStory2Popup-datas {
  margin: 50px auto 25px;
  background-color: black;
  display: flex;
}

.createStory2Popup-data {
  text-align: center;
}

.createStory2Popup-data-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0px 0px 10px;
  position: absolute;
  top: 7px;
  left: 25%;
  z-index: 1;
}

.createStory2Popup-data-icon {
  background-color: #0000006F;
  cursor: pointer;
  margin: 0px 7px;
  padding: 3px 10px 7px;
  border-radius: 50%;
}

.createStory2Popup-data-icon-img {
  height: 17px;
}

.createStory2Popup-data-image {
  max-height: 500px;
  max-width: 425px;
  margin: 3px 0px;
}

.createStory2Popup-data-video {
  height: 500px;
  width: 425px;
  margin: 3px 0px;
}

.createStory2Popup-data-playPauseButton {
  height: 50px;
  position: absolute;
  top: 45%;
  left: 45%;
}


.createStory2Popup-btn {
  width: 75%;
  text-align: center;
  margin: 0px 0px 0px 57px;
  color: white;
  background-color: #64A6F0;
  padding: 7px 0px;
  border-radius: 20px;
  font-size: 17px;
  cursor: pointer;
}









/* *** Center Block > Story 2 > following user story ***  */

.cb-story-2-fus {
  /* border: 1px solid green; */
  display: flex;
  width: 80%;
}

.cb-story-2-fus-slider {
  /* border: 1px solid yellow; */
  width: 576px;
}

.cb-story-2-fus-slider .slick-track {
  margin: 0px;
}

.cb-story-2-fus-stories {
  position: relative;
  margin: 5px;
}

.cb-story-2-fus-profile {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  z-index: 1;
  border: 1px solid white;
}

.cb-story-2-fus-story {
  height: 185px;
  width: 125px;
  border-radius: 10px;
  /* filter: blur(1px); */
  object-fit: cover;
  box-shadow: 0px 0px 7px 0px gray;
  margin: 0px 10px 0px 0px;
}

.cb-story-2-fus-name {
  /* border: 1px solid red; */
  height: auto;
  width: 105px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  text-transform: capitalize;
  text-align: center;
}



/* *** Center Block > Story 2 > Popup ***  */

.centerBlockStory2Popup {
  /* border: 1px solid black; */
  height: 700px;
  width: 435px;
}

.centerBlockStory2Popup-area-comment input {
  height: 40px;
  width: 300px;
  background-color: transparent;
  padding: 5px 15px;
  border: 1px solid gray;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 300;
  position: absolute;
  bottom: 35px;
  left: 17px;
  z-index: 1000;
}






/* ***** Home > Right Block *****  */


.section-main .right-block {
  position: relative;
  padding: 90px 40px 0px;
  box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.1);
  height: 100vh;
  overflow: auto;
  margin-bottom: 0px !important;
}



.section-main .right-block::-webkit-scrollbar {
  display: none;
}

.section-main .right-block .headBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.section-main .right-block .headBlock h6 {
width: 100%;
}

.section-main .right-block .headBlock a {
  font-size: 14px;
  font-weight: 500;
}

.section-main .right-block .headBlock2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  margin-bottom: 20px;
}

.section-main .right-block .headBlock2 .headBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 90%;
  margin-bottom: 0px;
}

.section-main .right-block .headBlock2 .headBlock h5 {
  font-size: 16px;
  font-weight: 700;
}

.section-main .right-block .headBlock2 .headBlock a {
  font-size: 14px;
  font-weight: 500;
}

.section-main .right-block .headBlock2 img {
  margin-right: 10px;
}

.section-main .right-block .headBlock2 p {
  font-size: 14px;
  margin-top: 10px;
}

.section-main .right-block .trendingBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.section-main .right-block .trendingBox .imgBox img {
  border-radius: 10px;
}



.section-main .right-block .groupBox {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.section-main .right-block .groupBox .imgBox img {
  border-radius: 10px;
  margin-right: 20px;
}



.section-main .right-block .groupBox .contentBox {
  position: relative;
  flex: 1;
}

.section-main .right-block .groupBox .contentBox .blockOne {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.section-main .right-block .groupBox .contentBox .blockOne .nameBox h6 {
  font-size: 15px;
  font-weight: 600;
  color: #484d54;
  margin-bottom: 5px;
}

.section-main .right-block .groupBox .contentBox .blockOne .nameBox p {
  font-size: 14px;
  line-height: 20px;
  color: #a5a5a5;
}

.section-main .right-block .groupBox .contentBox .blockTwo {
  margin-bottom: 5px;
}

.section-main .right-block .groupBox .contentBox .blockTwo p {
  font-size: 14px;
  line-height: 20px;
  color: #727272;
}

.section-main .right-block .groupBox .contentBox .blockThree {
  display: flex;
  align-items: center;
}

.section-main .right-block .groupBox .contentBox .blockThree .userIcon {
  position: relative;
  margin-right: 10px;
}

.section-main .right-block .groupBox .contentBox .blockThree .userIcon img {
  width: 30px;
  border: 2px solid #ffffff;
  border-radius: 50px;
}

.section-main
  .right-block
  .groupBox
  .contentBox
  .blockThree
  .userIcon
  img
  + img {
  margin-left: -15px;
}

.section-main .right-block .groupBox .contentBox .blockThree p {
  font-size: 14px;
  color: #a5a5a5;
}

.section-main .right-block .peopleBox {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.section-main .right-block .peopleBox .imgBox {
  position: relative;
  height: 65px;
  width: 60px;
}

.section-main .right-block .peopleBox .imgBox img {
  width: 70px;
  border-radius: 50%;
}

.section-main .right-block .peopleBox .imgBox.status img {
  outline: 2px solid #4580c2;
  border: 2px solid #ffffff;
}

.section-main .right-block .peopleBox .imgBox.active::before {
  content: "";
  position: absolute;
  bottom: 8px;
  right: -4px;
  width: 15px;
  height: 15px;
  background: #00b5aa;
  border: 2px solid #ffffff;
  border-radius: 15px;
}

.section-main .right-block .peopleBox .contentBox {
  position: relative;
  flex: 1;
  margin-left: 20px;
}



.section-main .right-block .peopleBox .contentBox .blockOne {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-main .right-block .peopleBox .contentBox .blockOne .nameBox h6 {
  font-size: 15px;
  font-weight: 600;
  color: #484d54;
  margin-bottom: 3px;
}

.section-main .right-block .peopleBox .contentBox .blockOne .nameBox p {
  font-size: 14px;
  line-height: 20px;
  color: #a5a5a5;
}

.section-main .chatLeft {
  position: relative;
  padding: 10px 25px 25px;
  height: 100vh;
  overflow: auto;
}

.section-main .chatLeft::-webkit-scrollbar {
  display: none;
}

.section-main .chatLeft .chatHead {
  display: flex;
  align-items: center;
}

.section-main .chatLeft .chatHead a {
  width: 100%;
  padding: 20px;
  color: #bababa;
  font-weight: 500;
  text-align: center;
  border-bottom: 2px solid #bababa;
}

.section-main .chatLeft .chatHead a.active {
  color: #000000;
  border-bottom: 2px solid #64a6f0;
}

.section-main .chatLeft .chatHead a img {
  margin-right: 10px;
}

.section-main .chatLeft .searchBox {
  position: relative;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.section-main .chatLeft .searchBox form {
  flex: 1 1 0px;
  margin-right: 25px;
}

.section-main .chatLeft .searchBox input {
  width: 100%;
  height: 45px;
  border-radius: 40px;
  border: 0;
  background: #f5f5f5;
  padding-left: 60px;
}

.section-main .chatLeft .searchBox input::placeholder {
  color: #bababa;
}

.section-main .chatLeft .searchBox button {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background: transparent;
  border: 0;
  color: #bababa;
}

.section-main .chatLeft .searchBox .btn {
  position: relative;
  left: 0;
  top: 0;
  height: auto;
  width: auto;
  color: #4b4b4b;
  font-size: 20px;
  padding: 0;
  line-height: 0;
  border: 0;
  transform: none;
  margin-left: 15px;
  background: transparent;
}

.section-main .chatLeft .searchBox .btn:focus {
  box-shadow: none !important;
}

.section-main .chatLeft .searchBox .btn::after {
  display: none;
}

.section-main .chatLeft .searchBox .dropdown-menu {
  min-width: max-content;
  margin: 0;
  padding: 15px 0px;
  border-radius: 15px;
  box-shadow: 0px 3px 15px #e1e1e1;
  border: 0;
}

.section-main .chatLeft .searchBox .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 10px 25px;
  display: flex;
  align-items: center;
}

.section-main .chatLeft .searchBox .dropdown-item img {
  margin-right: 10px;
}

.section-main .chatLeft .searchBox .dropdown-item b {
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: #cecece;
  font-weight: 400;
}

.section-main .chatLeft .userBox {
  position: relative;
  margin-top: 30px;
}

.section-main .chatLeft .userBox .headArea {
  display: flex;
  align-items: center;
}

.section-main .chatLeft .userBox .headArea img {
  margin-right: 5px;
}

.section-main .chatLeft .userBox .headArea h5 {
  font-size: 16px;
  font-weight: 600;
}

.section-main .chatLeft .userBox hr {
  background: #ebebeb;
  height: 1px;
  margin: 30px 0px 25px;
  border: 0;
}

.section-main .chatLeft .userBox .userArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.section-main .chatLeft .userBox .userArea .userDetail {
  position: relative;
  display: flex;
  align-items: center;
}

.section-main .chatLeft .userBox .userArea .userDetail .imgBox {
  position: relative;
  width: 55px;
  margin-right: 20px;
}

.section-main .chatLeft .userBox .userArea .userDetail .imgBox.active::before {
  content: "";
  position: absolute;
  top: 4px;
  right: -2px;
  width: 12px;
  height: 12px;
  background: #00b5aa;
  border: 2px solid #ffffff;
  border-radius: 15px;
}

.section-main .chatLeft .userBox .userArea .userDetail .imgBox.group {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 65px;
}

.section-main .chatLeft .userBox .userArea .userDetail .imgBox.group img {
  width: 55px;
  border: 1px solid #ffffff;
}

.section-main .chatLeft .userBox .userArea .userDetail .imgBox.group img + img {
  width: 50px;
  margin-right: -45px;
}

.section-main .chatLeft .userBox .userArea .userDetail .imgBox img {
  border-radius: 50%;
}

.section-main .chatLeft .userBox .userArea .userDetail .nameBox h6 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.section-main .chatLeft .userBox .userArea .userDetail .nameBox h6 span {
  font-size: 11px;
  font-weight: 400;
  color: #cecece;
  margin-left: 10px;
}

.section-main .chatLeft .userBox .userArea .userDetail .nameBox p {
  font-size: 13px;
  color: #a5a5a5;
}

.section-main .chatLeft .userBox .userArea .userMore {
  display: flex;
  align-items: center;
}

.section-main .chatLeft .userBox .userArea .userMore span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  background: #64a6f0;
  border-radius: 50%;
  margin-right: 15px;
}

.section-main .chatLeft .userBox .userArea .userMore .btn {
  color: #4b4b4b;
  font-size: 20px;
  padding: 0;
  line-height: 0;
  border: 0;
  background: transparent;
}

.section-main .chatLeft .userBox .userArea .userMore .btn:focus {
  box-shadow: none !important;
}

.section-main .chatLeft .userBox .userArea .userMore .btn::after {
  display: none;
}

.section-main .chatLeft .userBox .userArea .userMore .dropdown-menu {
  min-width: max-content;
  margin: 0;
  padding: 15px 0px;
  border-radius: 15px;
  box-shadow: 0px 3px 15px #e1e1e1;
  border: 0;
}

.section-main .chatLeft .userBox .userArea .userMore .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 10px 25px;
  display: flex;
  align-items: center;
}

.section-main .chatLeft .userBox .userArea .userMore .dropdown-item img {
  margin-right: 10px;
}

.section-main .chatLeft .userBox .userArea .userMore .dropdown-item b {
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: #cecece;
  font-weight: 400;
}

.section-main .chatLeft .userBox .userArea .userMore input {
  position: relative;
  margin: 0;
}

.section-main .chatRight {
  position: relative;
  box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.1);
  height: 80vh;
  overflow: auto;
}

.section-main .chatRight::-webkit-scrollbar {
  display: none;
}

.section-main .chatRight .userArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.section-main .chatRight .userArea .userDetail {
  position: relative;
  display: flex;
  align-items: center;
}

.section-main .chatRight .userArea .userDetail .imgBox {
  position: relative;
  width: 55px;
  margin-right: 20px;
}

.section-main .chatRight .userArea .userDetail .imgBox img {
  border-radius: 50%;
}

.section-main .chatRight .userArea .userDetail .nameBox h6 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.section-main .chatRight .userArea .userMore {
  display: flex;
  align-items: center;
}

.section-main .chatRight .userArea .userMore .callBox {
  display: flex;
}

.section-main .chatRight .userArea .userMore .callBox a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-right: 35px;
  background: #f2f2f2;
}

.section-main .chatRight .userArea .userMore .callBox a:first-child {
  margin-right: 25px;
}

.section-main .chatRight .userArea .userMore .btn {
  color: #4b4b4b;
  font-size: 20px;
  padding: 0;
  line-height: 0;
  border: 0;
  background: transparent;
}

.section-main .chatRight .userArea .userMore .btn:focus {
  box-shadow: none !important;
}

.section-main .chatRight .userArea .userMore .btn::after {
  display: none;
}

.section-main .chatRight .userArea .userMore .dropdown-menu {
  min-width: max-content;
  margin: 0;
  padding: 15px 0px;
  border-radius: 15px;
  box-shadow: 0px 3px 15px #e1e1e1;
  border: 0;
}

.section-main .chatRight .userArea .userMore .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 10px 25px;
  display: flex;
  align-items: center;
}

.section-main .chatRight .userArea .userMore .dropdown-item img {
  margin-right: 10px;
}

.section-main .chatRight .chatArea {
  padding: 30px 30px 50px 30px;
  /* padding: 30px ; */
}

.section-main .chatRight .chatArea .chatBox {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.section-main .chatRight .chatArea .chatBox span {
  background: #c4e9ff;
  padding: 12px 20px;
  border-radius: 12px 12px 12px 0px;
  font-size: 14px;
  max-width: 38%;
  word-wrap: break-word;
}

.section-main .chatRight .chatArea .chatBox span time {
  color: #b1b1b1;
  font-size: 12px;
  margin-left: 10px;
}

.section-main .chatRight .chatArea .chatBox.right {
  justify-content: flex-end;
}

.section-main .chatRight .chatArea .chatBox.right span {
  background: #e8e8e8;
  border-radius: 12px 12px 0px 12px;
}

.section-main .chatRight .chatArea .chatBox .imgGroup {
  display: flex;
  flex-wrap: wrap;
  max-width: 38%;
  background: #e8e8e8;
  padding: 5px;
  border-radius: 12px 12px 12px 0px;
}

.section-main .chatRight .chatArea .chatBox.right .imgGroup {
  border-radius: 12px 12px 0px 12px;
}

.section-main .chatRight .chatArea .chatBox .imgGroup .imgBox {
  position: relative;
  flex: 30%;
  margin: 6px;
  border-radius: 12px;
  overflow: hidden;
}

.section-main
  .chatRight
  .chatArea
  .chatBox
  .imgGroup
  .imgBox:last-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}

.section-main .chatRight .chatArea .chatBox .imgGroup .imgBox a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.section-main .chatRight .chatArea .chatBox .emojiBox {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.section-main .chatRight .chatArea .chatBox:hover .emojiBox {
  top: -35px;
  opacity: 1;
}

.section-main .chatRight .chatArea .chatBox .emojiBox img {
  margin-right: 14px;
  cursor: pointer;
}

.section-main .chatRight .chatArea .chatBox .emojiBox a {
  color: #b1b1b1;
  font-size: 24px;
}

.section-main .chatRight .chatArea .callBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.section-main .chatRight .chatArea .callBox:last-child {
  margin-bottom: 0px;
}

.section-main .chatRight .chatArea .callBox .callDetail {
  position: relative;
  display: flex;
  align-items: center;
}

.section-main .chatRight .chatArea .callBox .callDetail .imgBox {
  position: relative;
  width: 55px;
  margin-right: 20px;
}

.section-main .chatRight .chatArea .callBox .callDetail .imgBox img {
  border-radius: 50%;
}

.section-main .chatRight .chatArea .callBox .callDetail .nameBox h6 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.section-main .chatRight .chatArea .callBox .callDetail .nameBox h6 img {
  margin-left: 15px;
}

.section-main .chatRight .chatArea .callBox .callDetail .nameBox span {
  color: #8d8d8d;
  font-size: 12px;
}

.section-main .chatRight .chatArea .callBox .userMore {
  display: flex;
  align-items: center;
}

.section-main .chatRight .chatArea .callBox .userMore a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-right: 20px;
  background: #f2f2f2;
}

.section-main .chatRight .chatArea .callBox .userMore .btn {
  color: #4b4b4b;
  font-size: 20px;
  padding: 0;
  line-height: 0;
  border: 0;
  background: transparent;
}

.section-main .chatRight .chatArea .callBox .userMore .btn:focus {
  box-shadow: none !important;
}

.section-main .chatRight .chatArea .callBox .userMore .btn::after {
  display: none;
}

.section-main .chatRight .chatArea .callBox .userMore .dropdown-menu {
  min-width: max-content;
  margin: 0;
  padding: 15px 0px;
  border-radius: 15px;
  box-shadow: 0px 3px 15px #e1e1e1;
  border: 0;
}

.section-main .chatRight .chatArea .callBox .userMore .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 10px 25px;
  width: auto;
  height: auto;
  border-radius: 0;
  margin: 0;
  background: transparent;
  justify-content: flex-start;
}

.section-main .chatRight .chatArea .callBox .userMore .dropdown-item img {
  margin-right: 10px;
}

.section-main .chatRight .chatArea .groupCall {
  position: relative;
}

.section-main .chatRight .chatArea .groupCall .imgBox {
  text-align: center;
  margin: 50px 0 70px;
}

.section-main .chatRight .chatArea .groupCall .textBox {
  text-align: center;
}

.section-main .chatRight .chatArea .groupCall .textBox h6 {
  font-size: 15px;
  font-weight: 600;
}

.section-main .chatRight .chatArea .groupCall .textBox p {
  font-size: 12px;
  color: #848484;
}

.section-main .chatRight .chatArea .groupCall .userBox {
  margin: 80px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-main .chatRight .chatArea .groupCall .userBox .userImg {
  position: relative;
  margin-right: -25px;
}

.section-main .chatRight .chatArea .groupCall .userBox .userImg img {
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.section-main .chatRight .chatArea .groupCall .userBox .userImg::before {
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 20px;
  height: 20px;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  background: #ff6969;
  border-radius: 15px;
}

.section-main .chatRight .chatArea .groupCall .btnBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-main .chatRight .chatArea .groupCall .btnBox img {
  margin: 0px 20px;
}

.section-main .chatRight .footerArea {
  padding: 15px 30px;
  background: #ffffff;
  position: fixed;
  width: 50%;
  bottom: 0px;
}

.section-main .chatRight .footerArea .typeAria {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 25px;
  border: 1px solid #d9d9d9;
  border-radius: 50px;

}

.section-main .chatRight .footerArea .typeAria .inputBox {
  position: relative;
  flex: 0 0 70%;
  padding: 0px 10px;
}

.section-main .chatRight .footerArea .typeAria .inputBox input {
  width: 100%;
  padding-left: 30px;
  border: 0;
}

.section-main .chatRight .footerArea .typeAria .inputBox input::placeholder {
  color: #d9d9d9;
  font-size: 14px;
}

.section-main .chatRight .footerArea .typeAria .inputBox button {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: 0;
}

.section-main .chatRight .footerArea .typeAria .sendBox {
  display: flex;
  align-items: center;
  z-index: 100;
}

/* .section-main .chatRight .footerArea .typeAria .sendBox button , span {
  padding-right: 20px;
} */

.section-main .chatRight .footerArea .typeAria .sendBox .send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  background: #2676e1;
}

.footer-area {
  background: #f9f9f9;
  padding: 15px;
}

.footer-area .copyright {
  text-align: left;
}

.footer-area .copyright p {
  font-size: 14px;
  line-height: 14px;
  margin-top: 8px;
}

.footer-area .copyright a {
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  text-decoration: underline;
  margin-right: 10px;
}
.footer-area .copyright span {
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  text-decoration: underline;
  margin-right: 10px;
}

.footer-area .copyright a:last-child {
  margin-right: 0px;
}

.footer-area .copyright a:hover {
  text-decoration: none;
}

.error-area {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.error-area .errorBox {
  text-align: center;
}

.error-area .errorBox h1 {
  font-size: 200px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #4580c2;
}

.error-area .errorBox h1 span {
  color: #ed1e24;
  margin: 0px 10px;
}

.error-area .errorBox h2 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 50px;
}




/* ***** Continue *****  */

.continue {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

/* .continue-left {
  width: 50%;
  display: flex;
} */

.continue-left-image-1 {
  /* height: 850px;
  width: 300px; */
  overflow-y: hidden;
  position: absolute;
  top: 100px;
  left: 30%;
}

.continue-left-image-2 {
  height: 800px;
  width: 300px;
  overflow-y: hidden;
  position: absolute;
  top: 150px;
  left: 45%;
}


.continue-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

.continue-right-logo {
  height: 65px;
  width: 150px;
  margin: 8% 0px;
}

.google_body{
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.google_btn{
  position: relative;
  z-index: 1;
}


.google_btn_ui{
  position: absolute;
  top: 0px;
  z-index: 2;
  left: 10px;
  opacity: 0;
}


.continue-right-select {
  /* position: absolute; */
  margin: 0px 0px 30px;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  border-radius: 30px;
  cursor: pointer;
}

.continue-right-select-img {
  height: 30px;
  width: 30px;
  margin: 0px 10px;
  /* margin: 0px 50px 0px 100px; */
}

.continue-right-or {
  margin: 3% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 352px;
}

.continue-right-or-dash {
  border: 1px solid #BFBFBF;
  width: 200px;
  opacity: 0.5;
  height: 0.5px;
  margin: 0px 25px 5px;
}

.continue-right-createAccount {
  color: white ;
  width: 300px;
  text-align: center;
  background-color: #64A6F0;
  padding: 15px 0px;
  border-radius: 30px;
  cursor: pointer;
}

.continue-right-createAccount:hover {
  color: white;
}

.continue-right-tac {
  width: 50%;
  display: inline;
  text-align: center;
  margin: 5% 0px;
}
.continue-right-tac a{
  display: inline;
}

.continue_text{
  width: 352px;
  text-align: center;
  max-width: 352px;
  margin: 15px 0px;

}

.continue-right-language img {
  margin: 0px 15px 0px 0px;
}

.continue-right-language span {
  color: #2676E1;
}



.continue-right-login a {
  color: #2676E1;
  cursor: pointer;
}

.continue-right-login a:hover {
  color: #2676E1;
}










/* ***** Login *****  */

.login-area .loginBox {
  position: relative;
}

.login-area .loginBox h5 {
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
  color: #525252;
  margin-bottom: 40px;
}

.login-area .loginBox input {
  width: 100%;
  height: 60px;
  font-size: 16px;
  line-height: 60px;
  border: 1px solid #efefef;
  border-radius: 10px;
  outline: none;
  padding: 0 30px;
  margin-bottom: 30px;
}

.login-area .loginBox input[type="submit"] {
  width: 100%;
  height: 60px;
  font-size: 16px;
  line-height: 60px;
  border-radius: 50px;
  /* background: linear-gradient(90deg, #02a7fa 0%, #3d52ed 100%); */
  background: #64A6F0;
  color: #ffffff;
  text-align: center;
  border: none;
  outline: none;
  font-weight: 500;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.login-area .loginBox input[type="submit"]:hover {
  background: linear-gradient(90deg, #3d52ed 0%, #02a7fa 100%);
}

.login-area .loginBox input[type="checkbox"] {
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 10px;
  padding: 0;
  margin-bottom: 0;
  margin-right: 10px;
}

.login-area .loginBox span {
  color: #02a7fa;
  font-size: 14px;
  text-align: right;
  margin-top: 12px;
  margin-bottom: 30px;
  display: block;
}

.login-area .loginBox .divider {
  position: relative;
  border-bottom: 1px solid #bfbfbf;
  margin: 40px 0px;
}

.login-area .loginBox .divider b {
  position: absolute;
  top: -10px;
  left: 50%;
  background: #ffffff;
  transform: translateX(-50%);
  color: #525252;
  font-weight: 400;
  padding: 0px 25px;
}

.login-area .loginBox .socialLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.login-area .loginBox .socialLogin .socialIcon {
  background: #f8f8f8;
  padding: 10px 30px;
  margin: 0px 10px;
  text-align: center;
  border-radius: 15px;
}

.login-area .loginBox .bottomBox p {
  text-align: center;
  font-size: 14px;
}

.login-area .loginBox .bottomBox a {
  color: #525252;
  font-weight: 600;
  text-decoration: underline;
}

.login-area .loginBox .bottomBox a:hover {
  text-decoration: none;
}

.login-area .loginBox .signupBox {
  margin-top: 60px;
}

.login-area .loginBox .signupBox p {
  text-align: center;
  font-size: 14px;
}

.login-area .loginBox .signupBox a {
  color: #02a7fa;
  font-weight: 600;
  text-decoration: none;
}

.login-area .loginBox .signupBox a:hover {
  text-decoration: underline;
}



.login-area .errorBox {
  text-align: center;
}

.login-area .errorBox h1 {
  font-size: 200px;
  font-weight: 700;
  margin-bottom: 10px;
}

.login-area .errorBox h2 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 50px;
}




.sm-create-post{
  display: none;
}

.sm-profile-menu{
  display: none;
}

.sm-profile-nav{
  display: flex;
}

.profile-post-img{
  width: 73%;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  padding: 5px auto;
  flex-wrap: wrap;
  justify-content: left;
}

.centerBlockStory1 {
  /* border: 1px solid green; */
  margin: 5px 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.centerBlockStory1-storyImg {
  outline: 2px solid red;
  outline-offset: 2px;
  border-radius: 50%;
  object-fit: cover;
}

.centerBlockStory1-profileImg {
  outline: 2px solid #64A6F0;
  outline-offset: 2px;
  position: relative;
  left: 23px;
  bottom: 15px;
  width: 30px;
  border-radius: 50%;
}

.trendingFeeds { 
  display: flex;
  margin-bottom: 30px;
}

.home-rb-trendingFeeds {
  margin: 0px 0px 15px 0px;
}

.trendingFeedsImage {

  border-radius: 15px;
  margin-right: 30px;
  cursor: pointer;
}


/* ***** Navbar to show favourite, liked and tagged post ***** */

.navbar {
  /* border: 1px solid red; */
  margin: 30px auto;
  width: 35%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar-div {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbar-div img {
  margin: 0px 0px 10px 0px;
}

.navbar-div span {
  color: black;
}


/* ***** Favourite ***** */

.favourite {
  /* border: 1px solid black; */
  margin: 75px 0px 0px;
}

.favourite-show {
  /* border: 1px solid red; */
  width: 70%;
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
}

.favourite-show-div {
  /* border: 1px solid black; */
  height: 215px;
  width: 200px;
  box-shadow: 0px 0px 10px 0px gray;
  border-radius: 10px;
  position: relative;
}

.favourite-show-head {
  color: white;
  position: absolute;
  top: 35%;
  left: 25%;
  z-index: 1;
  text-shadow: 0px 0px 5px gray;
}

.favourite-show-reels-head {
  left: 33%;
}

.favourite-show-bestClips-head {
  left: 14%;
}

.favourite-show-memes-head {
  left: 23%;
}

.favourite-show-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  filter: blur(2px);
}

.favourite-show-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  filter: blur(2px);
}




/* ***** Favourite > Photo And Video ***** */

.favourite-posts {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 65%;
}

.favourite-posts-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

/* .favourite-posts-imgs {
  border: 1px solid red;
} */

.favourite-posts-img {
  height: 215px;
  width: 200px;
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
  position: relative;
  bottom: 125px;
  /* border: 1px solid green; */
}

.favourite-posts-video {
  height: 215px;
  width: 200px;
  object-fit: cover;
  cursor: pointer;
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  /* border: 1px solid green; */
}



/* ***** Favourite > Reels ***** */

.favourite-reels {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 65%;
}

.favourite-reels-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

.favourite-reels-img {
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
}


/* ***** Favourite > BestClips ***** */

.favourite-bestClips {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 65%;
}

.favourite-bestClips-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

/* .favourite-bestClips-imgs {
  border: 1px solid red;
} */

.favourite-bestClips-img {
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
}

.favourite-bestClips-video {
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
  height: 215px;
  width: 200px;
  object-fit: cover;
}


/* ***** Favourite > Memes ***** */

.favourite-memes {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 70%;
}

.favourite-memes-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

.favourite-memes-imgs {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 50px 0px 0px;
}

.favourite-memes-img {
  height: 215px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
  object-fit: cover;
  box-shadow: 0px 0px 5px 0px gray;
  margin: 0px 30px 60px;
}

.favourite-memes-video {
  height: 215px;
  width: 215px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px gray;
  margin: 0px 30px 60px;
}









/* ***** Liked ***** */

/* .liked {
  border: 1px solid black;
  margin: 75px 0px 0px;
} */

.liked-show {
  /* border: 1px solid red; */
  width: 1427px;
  margin: 0px auto;
  display: flex;
}

.liked-show-div {
  position: relative;
}

.liked-show-post-head {
  color: white;
  font-weight: 400;
  position: absolute;
  top: 30%;
  left: 22%;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
}

.liked-show-reels-head {
  color: white;
  font-weight: 400;
  position: absolute;
  top: 30%;
  left: 37%;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
}

.liked-show-bestClip-head {
  color: white;
  font-weight: 400;
  position: absolute;
  top: 30%;
  left: 30%;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
}

.liked-show-meme-head {
  color: white;
  font-weight: 400;
  position: absolute;
  top: 30%;
  left: 30%;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
}

.liked-show img {
  margin: 0px 42px 65px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 25px -15px gray;
  filter: blur(1px);
}


/* *** Liked > Posts *** */

.liked-posts {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 65%;
}

.liked-posts-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

/* .liked-posts-imgs {
  border: 1px solid red;
} */

.liked-posts-img {
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
}

/* *** Liked > Reels *** */

.liked-reels {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 65%;
}

.liked-reels-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

/* .liked-posts-imgs {
  border: 1px solid red;
} */

.liked-reels-img {
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
}


/* *** Liked > BestClips *** */

.liked-bestClips {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 65%;
}

.liked-bestClips-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

/* .liked-bestClips-imgs {
  border: 1px solid red;
} */

.liked-bestClips-img {
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
}


/* *** Liked > Memes *** */

.liked-memes {
  /* border: 1px solid black; */
  margin: 75px auto 0px;
  width: 65%;
}

.liked-memes-head {
  text-align: center;
  margin: 110px 0px 25px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

/* .liked-memes-imgs {
  border: 1px solid red;
} */

.liked-memes-img {
  margin: 23px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
}








/* ***** Tagged ***** */

/* .tagged {
  border: 1px solid black;
} */


/* ***** Tagged > Show ***** */

.tagged-show {
  /* border: 1px solid black; */
  width: 70%;
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
}


/* ***** Tagged > Show > Photos And Videos ***** */

.tagged-show-pav {
  /* border: 1px solid red; */
  position: relative;
}

.tagged-show-pav-head {
  position: absolute;
  top: 95px;
  left: 27px;
  color: white;
  z-index: 1;
  cursor: pointer;
}

.tagged-show-pav img {
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  cursor: pointer;
  filter: blur(2px);
  object-fit: cover;
}

.tagged-show-pav video {
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  cursor: pointer;
  filter: blur(1px);
  object-fit: cover;
}




/* ***** Tagged > Show > Best Clips ***** */

.tagged-show-bestClip {
  /* border: 1px solid red; */
  position: relative;
}

.tagged-show-bestClip-head {
  position: absolute;
  top: 95px;
  left: 50px;
  color: white;
  z-index: 1;
  cursor: pointer;
}

.tagged-show-bestClip video {
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  cursor: pointer;
  filter: blur(1px);
  object-fit: cover;
}


/* ***** Tagged > Show > Photos And Videos ***** */

.tagged-show-meme {
  /* border: 1px solid red; */
  position: relative;
}

.tagged-show-meme-head {
  position: absolute;
  top: 95px;
  left: 65px;
  color: white;
  z-index: 1;
  cursor: pointer;
}

.tagged-show-meme img {
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  box-shadow: 0px 0px 25px -15px gray;
  cursor: pointer;
  filter: blur(2px);
  object-fit: cover;
}

.tagged-show-meme video {
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  cursor: pointer;
  filter: blur(1px);
  object-fit: cover;
}










/* ***** Tagged > Photos And Videos ***** */

/* .tagged-pav {
  border: 1px solid black;
} */

.tagged-pav-head {
  text-align: center;
  margin: 110px 0px 50px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

.tagged-pav-show {
  /* border: 1px solid green; */
  width: 70%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}

.tagged-pav-show img {
  /* border: 1px solid red; */
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  margin: 0px 30px 60px;
  cursor: pointer;
  object-fit: cover;
}

.tagged-pav-show video {
  /* border: 1px solid red; */
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  margin: 0px 30px 60px;
  cursor: pointer;
  object-fit: cover;
}



/* ***** Tagged > Best Clips ***** */

/* .tagged-bestClips {
  border: 1px solid black;
} */

.tagged-bestClips-head {
  text-align: center;
  margin: 110px 0px 50px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

.tagged-bestClips-show {
  /* border: 1px solid green; */
  width: 70%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}

.tagged-bestClips-show video {
  height: 215px;
  width: 200px;
  /* border: 1px solid red; */
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  margin: 0px 30px 60px;
  cursor: pointer;
  object-fit: cover;
}




/* ***** Tagged > Memes ***** */

/* .tagged-memes {
  border: 1px solid black;
} */

.tagged-memes-head {
  text-align: center;
  margin: 110px 0px 50px;
  color: white;
  text-shadow: 0px 0px 15px lightslategray;
  cursor: pointer;
}

.tagged-memes-show {
  /* border: 1px solid green; */
  width: 70%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}

.tagged-memes-show img {
  /* border: 1px solid red; */
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  margin: 0px 30px 60px;
  cursor: pointer;
  object-fit: cover;
}

.tagged-memes-show video {
  /* border: 1px solid red; */
  height: 215px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px gray;
  margin: 0px 30px 60px;
  cursor: pointer;
  object-fit: cover;
}












/* ***** Favourite People Popup ***** */

/* .favouritePeoplePopup {
  border: 1px solid black;
} */

.favouritePeoplePopup-user {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 25px;
}

.favouritePeoplePopup-user-profile {
  border: 1px solid black;
  outline: 2px solid #0B71F6;
  outline-offset: 3px;
  cursor: pointer;
}

.favouritePeoplePopup-user-name {
  /* border: 1px solid black; */
  width: 225px;
  cursor: pointer;
  text-transform: capitalize;
}

.favouritePeoplePopup-user-button {
  /* border: 1px solid black; */
  color: white;
  background-color: #64A6F0;
  padding: 5px 30px;
  cursor: pointer;
  border-radius: 7px;
}


/* ***** Profile > BestClips ***** */

.profileBestClips {
  /* border: 1px solid black; */
  width: 73%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
}

.profileBestClips-bestClip {
  /* border: 1px solid yellow; */
  position: relative;
}

.profileBestClips-bestClip-delete {
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;
}

.profileBestClips-bestClip-image {
  /* border: 1px solid green; */
  margin: 0px 37px 47px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px -5px gray;
  object-fit: cover;
  cursor: pointer;
}



/* ***** Home > BestClips ***** */

.bestClips-categories {
  /* border: 1px solid red; */
  height: 50px;
  width: 100%;
}

.bestClips-categories div {
  /* border: 1px solid green; */
  width: auto;
  margin: 2px 5px;
  text-align: center;
  border-radius: 30px;
}

.bestClips-categories-Span {
  color: white;
  background-color: #64A6F0;
  border-radius: 10px;
  cursor: pointer;
  padding: 3px 10px;
  text-transform: capitalize;
  font-size: 14px;
}

.bestClips-categories-Span:hover {
  box-shadow: 0px 0px 7px 0px gray;
}

.bestClips-categories .slick-track{
  width: 100% !important;
}

.login_section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.login_left {
  height: 100vh;
  /* width: 50%; */
  background-color: #4590E5;
}

.login_right{
  height: 100%;
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

input::placeholder{
  opacity: 0.7 !important;
}


/* 
.login_right_inputFields {
  width: 60%;
  margin: 0px auto;
} */

.login_right_section{
  width: 80%;
  margin: auto;
}

.login_right_section>div:first-child  {
  width: 100%;
  padding: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}


.loginButton{
  /* width: 450px; */
  border-radius: 30px;
  margin: 15px 0px;
  padding: 15px;
  background-color: #03A5F9;
  outline: none;
  border: none;
}

.login_right input{
  /* 
  width: 450px;
  margin: auto;*/
  font-family: metropolis;
  margin: 10px 0px; 
  padding: 20px;
  border-radius:10px;
  border: 1px solid rgba(128, 128, 128, 0.568);
}

.login_right input::placeholder{
  font-family: metropolis;
  opacity: 0.8;
  font-size: 14px;
}

.rightInstructions{
  color: #2676E1 !important;
  font-weight: bold;
  margin: 10px 0px;
  cursor: pointer;
  display: block;
}

.explore {
  position: relative;
}

.explore-post-img {
  /* border: 1px solid black; */
  border-radius: 15px;
}

.explore-post-video {
  /* border: 1px solid black; */
  border-radius: 15px;
}

.explore-post-icon {
  /* border: 1px solid red; */
  background-color: #3E4347;
  opacity: 50%;
  height: 35px;
  width: 40px;
  border-radius: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
}

.explore-post-icon-img {
  height: 23px;
  width: 23px;
  margin: 5px 0px;
}


/* *** Profile User *** */

.profile-user-1 {
  width: 100%;
  /* background-color: blue; */
  margin: 120px auto;
}

.profile-user-2 {
  /* width: 125%; */
}

.profile-user-3 {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.profile-user-3-back {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-user-3-edit {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.profile-user-3-btn {
  /* border: 1px solid black; */
  width: 100%;
}

.profile-user-3-btn-msg {
  border: 1px solid #EBEBEB;
  border-radius: 7px;
  padding: 5px 17px;
  margin: 0px 50px;
  font-weight: 400;
  cursor: pointer;
  color: black;
}

.profile-user-3-btn-td {
  cursor: pointer;
}

.profile-user-3-btn-mytd {
  margin: 10px 0px 0px;
  cursor: pointer;
}

.profileUserThreeDotOverlay {
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: 10px 5px;
  cursor: pointer;
}

.profileUserThreeDotOverlayLast {
  border: none;
  padding: 10px 5px;
  cursor: pointer;
}

.profileUserThreeDotOverlayImg {
  height: 15px;
}

.profileUserThreeDotOverlaySpan {
  margin: 0px 0px 5px 10px;
}

.profile-user-hr {
  margin: 0px;
}

.PhoneInputInput{
  font-size: 16px !important;
}

.profile-nav {
  align-items: center;
  margin: 20px;
  padding: 20px;
  width: 75%;
  margin: 20px auto;
}

.profile-nav-ul {
  justify-content: space-evenly;
  width: 60%;
}

.profile-nav-li-t {
  text-align: center;
  cursor: pointer;
  width: 150px;
  padding: 10px 0px;
  border-radius: 15px;
  background-color: rgb(245, 246, 250);
  margin: 0px auto;
}

.profile-nav-li-f {
  text-align: center;
  cursor: pointer;
  padding: 10px 0px;
}










/* *** Profile User > Three Dot > Share Popup *** */

.putdsp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.putdsp-head {
  font-size: 25px;
  margin-bottom: 10px;
}

.putdsp-form {
  display: flex;
  border-radius: 30px;
}

.putdsp-form-img {
  padding: 10px 15px;
  background-color: #F5F5F5;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.putdsp-form-img img {
  height: 20px;
}

.cb-post-hr{
  opacity: 0.09 !important;
}

.putdsp-form label {
  margin: 0px;  
}

.putdsp-form input {
  padding: 10px ;
  font-size: 17px;
  width: 350px;
  border: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #F5F5F5;
}

.putdsp-users {
  border: 1px solid #F5F5F5;
  margin: 20px 0px 10px;
  height: 300px;
  width: 95%;
  overflow-y: scroll;
  padding: 0px 10px;
  border-radius: 10px;
}

.putdsp-users::-webkit-scrollbar {
  width: 0px;
}

.putdsp-user {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 0px 7px 0px;
}

.putdsp-user-img {
  height: 43px;
  width: 43px;
  border-radius: 50%;
  margin: 0px 15px 0px 0px;
}

.putdsp-user-img img {
  border-radius: 50%;
}

.putdsp-user-header {
  display: flex;
  flex-direction: column;
}

.putdsp-user-name {
  font-size: 17px;
  font-weight: 600;
}


.putdsp-selected {
  border: 1px solid #F5F5F5;
  max-height: 135px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  border-radius: 10px;
}

.putdsp-selected::-webkit-scrollbar {
  width: 0px;
}

.putdsp-selected-img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin: 5px 7px;
  position: relative;
}

.putdsp-selected-img img {
  border-radius: 50%;
}

.putdsp-selected-remove {
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
}


.putdsp-send {
  width: 90%;
  text-align: center;
  font-size: 20px;
  border-radius: 30px;
  padding: 5px 0px;
  color: white;
  background-color: #64A6F0;
  cursor: pointer;
  margin: 20px 0px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.loggedInDeviceName {
  max-height: 50px;
  width: 600px;
}

.aspect-ratio-9by16 {
  position: relative;
  width: 100%;
  padding-bottom: 177.78%; /* (16/9) * 100% */
  height: 0;
}

.aspect-ratio-9by16 .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.highlight {
  background-color: yellow; /* Change to your preferred highlight color */
  transition: background-color 0.5s ease; /* Smooth transition for the highlight */
}






















